@font-face {
  font-family: "PP Pangram Medium";
  src: url("./PPPangramSansMedium.otf");
  font-display: swap;
}

@font-face {
  font-family: "PP Pangram Light";
  src: url("./PPPangramSansLight.otf");
  font-display: swap;
}

@font-face {
  font-family: "PP Pangram SemiBold";
  src: url("./PPPangramSansSemibold.otf");
  font-display: swap;
}

@font-face {
  font-family: "PP Pangram Bold";
  src: url("./PPPangramSansBold.otf");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./Inter_VariableFont_slnt_wght.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("./PoppinsBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Poppins Medium";
  src: url("./PoppinsMedium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Poppins Semi Bold";
  src: url("./PoppinsSemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "WIX-Regular";
  src: url("./WixMadeforDisplay/WixMadeforDisplay-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "WIX-Medium";
  src: url("./WixMadeforDisplay/WixMadeforDisplay-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "WIX-SemiBold";
  src: url("./WixMadeforDisplay/WixMadeforDisplay-SemiBold.ttf");
  font-display: swap;
}
